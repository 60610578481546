<template>
  <div class="bg-white">
    <div data-app :class="`d-flex flex-column ${isMobile ? 'my-5' : 'my-10'}`" v-if="myEventList">
      <b-tabs :fill="isMobile" :class="isMobile ? 'mx-1' : 'mx-10'" v-model="tabIndex">
        <b-tab :title="$t(tab == 1 ? 'event' : 'drink')" v-for="tab in 2" :key="tab">
          <template #title>
            <span :class="`font-15 font-bold ${tab - 1 == tabIndex ? 'color-pink' : ''} ${isMobile ? '' : 'px-20 py-2'}`">{{ $t(tab == 1 ? 'event' : 'drink') }}</span>
          </template>
          <div class="d-flex flex-column" v-for="i in 2" :key="i">
            <div :class="`${isMobile ? 'mt-5 mx-10' : 'mt-10 mx-15'} d-flex justify-content-between vertical-center`">
              <div class="d-flex flex-column">
                <span :class="`font-bold ${isMobile ? 'font-15' : 'font-25'}`">{{ `${i == 1 ? $t('live_event') : $t('past_event')}` }}</span>
                <span :class="`${isMobile ? 'mt-2 font-10' : 'mt-5 font-15'}`">{{ `${i == 1 ? $t('_incoming_events', [liveList.length]) : $t('_past_events', [pastList.length])}` }}</span>
              </div>
              <v-btn :class="`vertical-center ${isMobile ? 'button-small' : 'button-normal'}`" @click="$router.push(`/add_event`)" v-if="i == 1">
                <i :class="`${isMobile ? 'mx-3' : 'mx-5'} fa fa-plus font-12 color-white`" />
                <span :class="`${isMobile? 'mr-3' : 'mr-5'}`">{{ $t('add_event') }}</span>
              </v-btn>
            </div>
            <div :class="`row ${isMobile ? 'm-2' : 'mx-10 mt-2'}`" v-if="(i == 1 ? liveList : pastList).length > 0">
              <div class="my-2 col-xl-3 col-lg-4 col-md-6 col-sm-12" v-for="(item, index) in (i == 1 ? liveList : pastList)" :key="(item, index)">
                <v-card class="mx-auto box-white p-0 d-flex flex-column">
                  <v-img class="rounded" height="200px" :src="item.photoUrl" />
                  <div class="mt-5 mx-5 d-flex justify-content-between vertical-center">
                    <span :class="`ellipsis font-bold ${isMobile ? 'font-15' : 'font-18'}`" :title="item.name">{{ item.name }}</span>
                    <span :class="`ml-5 box-pink-light px-5 py-1 color-pink ${isMobile ? 'font-12' : 'font-15'}`">{{ getPriceFormat(tab == 1 ? item.netSales : item.drinkPayoutAmount) }}</span>
                  </div>
                  <div class="mt-3 mx-5 vertical-center">
                    <i class="fa fa-calendar-alt font-12 color-icon"></i>
                    <span class="ml-2 font-12">{{ getDateStringFromTimestamp(item.startAt, 'ddd, DD MMM • hh:mm A') }}</span>
                  </div>
                  <div class="mt-5 mx-5 d-flex justify-content-between vertical-center">
                    <span class="font-label">{{ $t(tab == 1 ? 'sold_tickets' : 'sold_drinks') }}</span>
                    <span class="font-bold font-12">{{ tab == 1 ? `${getDecimalFormat(item.soldQuantity)} / ${getDecimalFormat(item.totalQuantity)}` : `${getDecimalFormat(item.drinkSoldQuantity)} / ${getDecimalFormat(item.drinkTotalQuantity)}` }}</span>
                  </div>
                  <div class="mt-1 mx-5">
                    <v-progress-linear color="#B91AA8" :value="tab == 1 ? (item.soldQuantity * 100 / item.totalQuantity) : (item.drinkSoldQuantity * 100 / item.drinkTotalQuantity)" />
                  </div>
                  <div class="m-5 d-flex justify-content-between" v-if="tab == 1">
                    <v-btn icon @click="$router.push(`/edit_event/${item.eventId}`)" v-if="i == 1">
                      <i class="fa fa-edit color-pink font-20"></i>
                    </v-btn>
                    <v-btn icon @click="showEditEventDialog(item)">
                      <i class="fa fa-clone color-pink font-20"></i>
                    </v-btn>
                    <v-btn icon @click="deleteEventTask(item)" v-if="i == 1">
                      <i class="ml-1 flaticon-delete-1 color-red font-20"></i>
                    </v-btn>
                    <v-btn class="ml-auto button-small" @click="$router.push(`/dashboard/${item.eventId}`)" :disabled="!item.ticketCount || item.ticketCount === 0">
                      <span :class="`${isMobile? 'mx-2' : 'mx-5'}`">{{ $t('dashboard') }}</span>
                    </v-btn>
                  </div>
                  <div class="m-5 mx-5 d-flex justify-content-between" v-else>
                    <v-btn icon @click="$router.push(`/edit_drink/${item.eventId}`)">
                      <i class="ml-1 fa fa-plus color-pink font-20" v-if="!item.drinkTotalQuantity"></i>
                      <i class="ml-1 fa fa-edit color-pink font-20" v-else></i>
                    </v-btn>
                    <v-btn icon @click="showSelectEventDialog(item)" v-if="canImportDrinks(item)">
                      <i class="ml-1 fas fa-file-import color-pink font-20"></i>
                    </v-btn>
                    <v-btn class="ml-auto button-small" @click="$router.push(`/drink_dashboard/${item.eventId}`)" :disabled="!item.drinkTotalQuantity || item.drinkTotalQuantity === 0">
                      <span :class="`${isMobile? 'mx-2' : 'mx-5'}`">{{ $t('dashboard') }}</span>
                    </v-btn>
                  </div>
                </v-card>
              </div>
            </div>
            <div class="my-30 center" v-else>
              <p>{{ $t('no_data_found') }}</p>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </div>
    <div class="my-30 py-40 center" v-else>
      <b-spinner type="grow"></b-spinner>
      <p class="mt-5">{{ $t('alert_please_wait') }}</p>
    </div>

    <!-- Start Edit Event Dialog -->
    <v-dialog v-model="visibleEditEventDialog" :width="isWide ? '600px' : ''">
      <v-card>
        <v-card-title class="bg-pink d-flex justify-content-between">
          <span :class="`font-bold color-white ${isMobile ? 'font-15' : 'font-18'}`">{{ $t('enter_new_event_info') }}</span>
          <v-btn class="m-1" icon small @click="visibleEditEventDialog = false">
            <i class="flaticon-cancel font-bold font-25 color-white"></i>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div class="m-5 d-flex flex-column">
            <div :class="isMobile ? 'm-2' : 'w-100 d-flex flex-column'">
              <div :class="`mt-5 d-flex ${isMobile ? 'flex-column' : 'justify-content-between vertical-center'}`">
                <span :class="`font-label ${isMobile ? 'ml-3' : ''}`">{{ $t('event_name') }}</span>
                <input :class="`input-normal ${isMobile ? 'mt-1' : 'w-75'}`" v-model="eventName" maxlength="100" oninput="this.value = this.value.replace(/[^0-9a-zA-Z \S]/g,'');" />
              </div>
              <div :class="`mt-5 d-flex ${isMobile ? 'flex-column' : 'justify-content-between vertical-center'}`">
                <span :class="`font-label ${isMobile ? 'ml-3' : ''}`">{{ $t('start_date') }}</span>
                <div :class="`d-flex ${isMobile ? 'mt-1' : 'w-75'}`">
                  <DatePicker
                    :class="`${isMobile ? 'w-50' : ''}`"
                    v-model="startDate"
                    format="DD.MM.YYYY"
                    type="date"
                    :placeholder="$t('start_date')"
                  />
                  <DatePicker
                    :class="`${isMobile ? 'w-50' : 'ml-1'}`"
                    v-model="startTime"
                    format="HH:mm"
                    type="time"
                  />
                </div>
              </div>
              <div :class="`mt-5 d-flex ${isMobile ? 'flex-column' : 'justify-content-between vertical-center'}`">
                <span :class="`font-label ${isMobile ? 'ml-3' : ''}`">{{ $t('end_date') }}</span>
                <div :class="`d-flex ${isMobile ? 'mt-1' : 'w-75'}`">
                  <DatePicker
                    :class="`${isMobile ? 'w-50' : ''}`"
                    v-model="endDate"
                    format="DD.MM.YYYY"
                    type="date"
                    :placeholder="$t('end_date')"
                  />
                  <DatePicker
                    :class="`${isMobile ? 'w-50' : 'ml-1'}`"
                    v-model="endTime"
                    format="HH:mm"
                    type="time"
                  />
                </div>
              </div>
            </div>
            <hr />
            <div class="w-100 d-flex justify-content-end">
              <v-btn class="button-small" @click="duplicateEventInfoTask()">
                {{ $t('ok') }}
              </v-btn>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- End Edit Event Dialog -->

    <!-- Start Select Event Dialog -->
    <v-dialog v-model="visibleSelectEventDialog" :width="isWide ? '600px' : ''">
      <v-card>
        <v-card-title class="bg-pink d-flex justify-content-between">
          <span :class="`font-bold color-white ${isMobile ? 'font-15' : 'font-18'}`">{{ $t('select_event') }}</span>
          <v-btn class="m-1" icon small @click="visibleEditEventDialog = false">
            <i class="flaticon-cancel font-bold font-25 color-white"></i>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div class="m-5 d-flex flex-column" v-if="getEventListForDrinkClone().length > 0">
            <div class="my-2 box-white px-3 py-2 pointer" v-for="(item, index) in getEventListForDrinkClone()" :key="index"  @click="handleSelectEvent(item)">
              <div class="mt-2 vertical-center">
                <span class="font-12 color-blue">{{ getDateStringFromTimestamp(item.startAt) }} - {{ getDateStringFromTimestamp(item.endAt) }}</span>
              </div>
              <span class="ellipsis font-bold font-18" :title="item.name"> {{ item.name }}</span>
            </div>
          </div>
          <div class="text-center" v-else>
            {{ $t('no_data_found') }}
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- End Select Event Dialog -->

    <!-- Start Add Employee Dialog -->
    <v-dialog v-model="visibleAddEmployeeDialog" :width="isWide ? '600px' : ''">
      <v-card>
        <v-card-title class="bg-pink d-flex justify-content-between">
          <span :class="`font-bold color-white ${isMobile ? 'font-15' : 'font-18'}`">{{ $t('add_employee') }}</span>
          <v-btn class="m-1" icon small @click="closeAddEmployeeDialog()">
            <i class="flaticon-cancel font-bold font-25 color-white"></i>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div class="m-5 d-flex flex-column">
            <div :class="isMobile ? 'm-2' : 'w-100 d-flex flex-column'">
              <div>{{ $t('desc_for_employee_duplication') }}</div>
              <div :class="`mt-5 d-flex ${isMobile ? 'flex-column' : 'justify-content-between vertical-center'}`" v-for="(item, index) in selectedEmployees" :key="index">
                <span :class="`font-label ${isMobile ? 'ml-3' : ''}`">{{ item.name }}</span>
                <input :class="`input-normal ${isMobile ? 'mt-1' : 'w-50'}`" v-model="newEmployeeNames[index]" maxlength="100" oninput="this.value = this.value.replace(/[^0-9a-zA-Z \S]/g,'');" />
              </div>
            </div>
            <hr />
            <div class="w-100 d-flex justify-content-end">
              <v-btn class="button-small" @click="addNewEmployeeTask()">
                {{ $t('ok') }}
              </v-btn>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- End Add Employee Dialog -->
  </div>
</template>

<script>
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../../main';
import { isFutureTimestamp, getCountryCityFromAddress, getDateStringFromTimestamp, getPriceFormat, getDecimalFormat, showLoading, showFunctionError } from '../../../functions';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { Timestamp, getDocs, query, collection, where } from 'firebase/firestore';
import { firestore } from '../../../main';

export default {
  name: 'MyEvents',
  components: {
    DatePicker
  },
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
    isWide() {
      return this.$store.state.isWide;
    },
    myInfo() {
      return this.$store.state.myInfo;
    },
    myEventList() {
      return this.$store.state.myEventList;
    }
  },
  watch: {
    myEventList() {
      this.refreshData();
    }
  },
  data() {
    const curDate = new Date();
    return {
      tabIndex: 0,
      liveList: [],
      pastList: [],
      items: null,
      visibleEditEventDialog: false,
      visibleSelectEventDialog: false,
      visibleAddEmployeeDialog: false,
      eventName: '',
      startDate: null,
      startTime: curDate,
      endDate: null,
      endTime: curDate,
      selectedEvent: null,
      targetEvent: null,
      selectedEmployees: [],
      newEmployeeNames: [],
      targetDrinkList: [],
    };
  },
  mounted() {
    if (this.myInfo) {
      if (this.myInfo.userType === this.USER_ORGANIZER) {
        if (this.$route.params.type === 'add_event') {
          this.$router.push('/add_event');
        } else {
          if (this.$route.params.type === '1') {
            setTimeout(() => {
              this.tabIndex = 1;
            }, 100);
          }
          this.refreshData();
        }
      } else {
        this.$toast.error('No permission!!');
        this.$router.push('/');
      }
    } else {
      this.$toast.info(this.$t('alert_sign_in'));
      this.$router.push('/');
    }
  },
  methods: {
    isFutureTimestamp,
    getCountryCityFromAddress,
    getDateStringFromTimestamp,
    getPriceFormat,
    getDecimalFormat,
    refreshData() {
      if (this.myEventList) {
        const myId = this.$store.state.myId;
        this.liveList = this.myEventList.filter(element => isFutureTimestamp(element.endAt)).sort((a, b) => (a.startAt.seconds > b.startAt.seconds) ? 1 : -1);
        this.pastList = this.myEventList.filter(element => !isFutureTimestamp(element.endAt)).sort((a, b) => (a.endAt.seconds < b.endAt.seconds) ? 1 : -1);
      }
    },
    checkPermission() {
      if (this.myInfo) {
        if (this.myInfo.userType === this.USER_ORGANIZER) {
          return true;
        } else {
          this.$toast.error('No permission!!');
          this.$router.push('/');
          return false;
        }
      } else {
        this.$toast.info(this.$t('alert_sign_in'));
        this.$router.push('/');
        return false;
      }
    },
    deleteEventTask(eventInfo) {
      if (eventInfo.userId !== this.$store.state.myId) {
        this.$toast.error('This event is not yours!');
        return;
      }
      if (!isFutureTimestamp(eventInfo.endAt)) {
        this.$toast.error('This event is already expired!');
        return;
      }
      if (!confirm(this.$t('confirm_delete'))) {
        return;
      }
      const params = {
        isDelete: true,
        userId: this.$store.state.myId,
        eventId : eventInfo.eventId
      };
      const loader = showLoading(this, 1);
      const func = httpsCallable(functions, 'setEventInfo');
      func(JSON.stringify(params)).then(response => {
        loader.hide();
        if (response.data === this.RESULT_SUCCESS) {
          this.$toast.info(this.$t('alert_deleted_successfully'));
        } else {
          showFunctionError(this, response.data);
        }
      }).catch(error => {
        loader.hide();
        this.$toast.error(error.code + ', ' + error.message);
      });
    },
    canImportDrinks(eventInfo) {
      const employees = this.$store.state.employeeList.filter(element => element.eventId === eventInfo.eventId);
      if (employees.length === 0 && !eventInfo.drinkTotalQuantity && isFutureTimestamp(eventInfo.startAt)) {
        return true;
      }
      return false;
    },
    handleSelectEvent(eventInfo) {
      this.targetEvent = eventInfo;
      this.visibleSelectEventDialog = false;
      // Get employee list from the eventInfo
      const employees = this.$store.state.employeeList.filter(element => element.eventId === eventInfo.eventId && element.role === 2 && element.userId === this.$store.state.myId);
      if (employees.length > 0) {
        this.getDrinkInfo();
        this.selectedEmployees = employees;
        this.visibleAddEmployeeDialog = true;
      } else {
        this.$toast.error(this.$t('alert_not_valid_event'));
      }
    },
    closeAddEmployeeDialog() {
      this.visibleAddEmployeeDialog = false;
      this.selectedEmployees = [];
      this.newEmployeeNames = [];
    },
    getEventListForDrinkClone() {
      if (this.myEventList) {
        return this.myEventList.filter(element => (!this.selectedEvent || element.eventId !== this.selectedEvent.eventId) && element.drinkTotalQuantity > 0);
      } else {
        return [];
      }
    },
    formatEventInfo() {
      this.selectedEvent = null;
      this.eventName = '';
      this.startDate = null;
      this.startTime = new Date();
      this.endDate = null;
      this.endTime = new Date();
    },
    showSelectEventDialog(eventInfo) {
      this.selectedEvent = eventInfo;
      this.visibleSelectEventDialog = true;
    },
    showEditEventDialog(eventInfo) {
      this.formatEventInfo();
      this.selectedEvent = eventInfo;
      this.visibleEditEventDialog = true;
    },
    getDrinkInfo() {
      this.targetDrinkList = [];
      const q = query(collection(firestore, 'drink'), where('eventId', '==', this.targetEvent.eventId), where('deletedAt', '==', null));
      getDocs(q).then(querySnapshot => {
        querySnapshot.forEach(doc => {
          const data = doc.data();
          this.targetDrinkList.push(data);
        });
      });
    },
    addNewEmployeeTask() {
      if (!this.selectedEvent) {
        this.$toast.error(this.$t('alert_unexpected_error'));
        return;
      }
      if (!this.newEmployeeNames.length || this.newEmployeeNames.length !== this.selectedEmployees.length) {
        this.$toast.error(this.$t('alert_required_field', [this.$t('employee_name')]));
        return;
      }
      const allMyEmployeeNames = [];
      this.$store.state.employeeList.forEach(element => {
        if (element.userId === this.$store.state.myId) {
          allMyEmployeeNames.push(element.name);
        }
      });
      // Check if new employee name is already exist
      this.newEmployeeNames.forEach(element => {
        const newName = element.trim();
        if (!newName) {
          this.$toast.error(this.$t('alert_required_field', [this.$t('employee_name')]));
          return;
        }
        if (allMyEmployeeNames.includes(newName)) {
          this.$toast.error(this.$t('alert_employee_name_exist', [this.$t(newName)]));
          return;
        }
        allMyEmployeeNames.push(newName);
      });
      
      // Create employee data and make function call
      const employeeNameList = this.newEmployeeNames.map(element => element.trim());
      const params = {
        userId: this.$store.state.myId,
        eventId: this.selectedEvent.eventId,
        role: 2,
        inUse: true,
        isBulk: true,
        nameList: employeeNameList,
      };
      const loader = showLoading(this, 1);
      const func = httpsCallable(functions, 'setEmployeeInfo');
      func(JSON.stringify(params)).then(response => {
        if (response.data === this.RESULT_SUCCESS) {
          this.createDrinkTask(loader);
        } else {
          showFunctionError(this, response.data);
        }
      }).catch(error => {
        loader.hide();
        this.$toast.error(error.code + ', ' + error.message);
      });
    },
    createDrinkTask(loader) {
      const drinkList = [];
      if (this.targetEvent && this.targetDrinkList.length > 0) {
        const origList = this.targetDrinkList.filter(element => element.eventId === this.targetEvent.eventId);
        if (origList && origList.length) {
          origList.forEach(drinkInfo => {
            const newDrinkInfo = {}
            newDrinkInfo.drinkId = drinkInfo.drinkId;
            newDrinkInfo.name = drinkInfo.name;
            newDrinkInfo.price = drinkInfo.price;
            newDrinkInfo.category = drinkInfo.category;
            newDrinkInfo.color = drinkInfo.color;
            newDrinkInfo.totalQuantity = drinkInfo.totalQuantity;
            if (drinkInfo.parentIDs && drinkInfo.parentIDs.length === 2) {
              newDrinkInfo.parentIDs = drinkInfo.parentIDs;
            }
            const totalMap = {};
            totalMap[this.$store.state.myId] = drinkInfo.totalQuantityMap[this.$store.state.myId] || 0;
            this.newEmployeeNames.forEach((name, index) => {
              const trimmedName = name.trim();
              totalMap[trimmedName] = drinkInfo.totalQuantityMap[this.selectedEmployees[index].name] || 0;
            });
            newDrinkInfo.totalQuantityMap = totalMap;
            drinkList.push(newDrinkInfo);
          });
          if (drinkList.length) {
            const params = {
              userId: this.$store.state.myId,
              eventId: this.selectedEvent.eventId,
              drinkList: drinkList,
            };
            const func = httpsCallable(functions, 'duplicateDrinkInfo');
            func(JSON.stringify(params)).then(response => {
              loader.hide();
              if (response.data === this.RESULT_SUCCESS) {
                this.$toast.info(this.$t('alert_added_successfully'));
                this.visibleAddEmployeeDialog = false;
                this.selectedEmployees = [];
                this.newEmployeeNames = [];
                this.$router.push(`/edit_drink/${this.selectedEvent.eventId}`);
              } else {
                showFunctionError(this, response.data);
              }
            }).catch(error => {
              loader.hide();
              this.$toast.error(error.code + ', ' + error.message);
            });
          } else {
            this.$toast.error(this.$t('alert_not_valid_event'));
            loader.hide();
          }
        } else {
          this.$toast.error(this.$t('alert_not_valid_event'));
          loader.hide();
        }
      } else {
        this.$toast.error(this.$t('alert_not_valid_event'));
        loader.hide();
      }
    },
    duplicateEventInfoTask() {
      if (!this.checkPermission()) {
        return;
      }
      if (!this.selectedEvent) {
        this.$toast.error(this.$t('alert_unexpected_error'));
        return;
      }
      if (!this.eventName) {
        this.$toast.error(this.$t('alert_required_field', [this.$t('event_name')]));
        return;
      }
      if (this.eventName.length < 4) {
        this.$toast.error(this.$t('alert_min_characters', [4, this.$t('event_name')]));
        return;
      }
      if (!this.startDate) {
        this.$toast.error(this.$t('alert_select_start_date'));
        return;
      }
      if (!this.endDate) {
        this.$toast.error(this.$t('alert_select_end_date'));
        return;
      }
      this.startDate.setHours(this.startTime.getHours());
      this.startDate.setMinutes(this.startTime.getMinutes());
      this.endDate.setHours(this.endTime.getHours());
      this.endDate.setMinutes(this.endTime.getMinutes());
      if (this.endDate.getTime() < this.startDate.getTime()) {
        this.$toast.error(this.$t('alert_end_date_must_be_greater_than_start_date'));
        return;
      }
      const newEventInfo = {
        userId: this.$store.state.myId,
        name: this.eventName,
        photoUrl: this.selectedEvent.photoUrl,
        mediaRatio: this.selectedEvent.mediaRatio,
        startAt: Timestamp.fromDate(this.startDate),
        endAt: Timestamp.fromDate(this.endDate),
        hideAddress: this.selectedEvent.hideAddress,
        address: this.selectedEvent.address,
        geoPoint: this.selectedEvent.geoPoint,
        genres: this.selectedEvent.genres,
        description: this.selectedEvent.description,
        facebookPixelId: this.selectedEvent.facebookPixelId,
        tikTokPixelId: this.selectedEvent.tikTokPixelId,
        onlyVisibleToMe: this.selectedEvent.onlyVisibleToMe,
      }
      const loader = showLoading(this, 1);
      const func = httpsCallable(functions, 'setEventInfo');
      func(JSON.stringify(newEventInfo)).then(response => {
        loader.hide();
        if (response.data === this.RESULT_SUCCESS) {
          this.formatEventInfo();
          this.$toast.info(this.$t('alert_added_successfully'));
          this.visibleEditEventDialog = false;
        } else {
          showFunctionError(this, response.data);
        }
      }).catch(error => {
        loader.hide();
        this.$toast.error(error.code + ', ' + error.message);
      });
    }
  }
};
</script>